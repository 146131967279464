import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NarrowContent from "../components/visualContainers/narrowContent"
import PageTitle from "../components/contentComponents/pageTitle"
import {H2, H3, P} from "../components/contentComponents/text"
import styled from "styled-components"
import WideImage from "../components/contentComponents/wideImage"
import MaterialContainer from "../components/visualContainers/materialContainer"
import mainImage from "../images/financier1.jpg"
import Wall from "../components/visualComponents/wall"
import Button, { Buttons } from "../components/contentComponents/button"
import Disc from "../components/visualComponents/disc"

const FinancierPage = (props) => {
  const t = props.pageContext.pageData
  const { site, locale, pageName, fullPath } = props.pageContext

  return (
    <Layout site={site} language={locale} pagePath={props.path} pageName={pageName}>
      <Seo title={t.pageTitle} lang={locale} pagePath={fullPath} site={site} meta={t.seo} />
      <NarrowContent>
        <PageTitle title={t.hero.title} />
        <TextUnderPageTitle strong={true} addMargins={true}>
          <Wall id={"wall1"} hideMobile={true} height={64} position="top: 0; right: -70px;"/>
          {t.hero.body}
        </TextUnderPageTitle>
        <Buttons className="center" mb={40}>
          <Button title={t.hero.topButtonTitle} linkTo={site.pages.contact.defaultPath}/>
        </Buttons>
      </NarrowContent>
      <WideImage image={mainImage}>
        <BubbleAnimator>
          <BubbleContainer className="center-vertical" material="blueGradient" circle={true} width={300} zIndex={6}>
            <P center={true} white={false}>{t.hero.bubbleBody}</P>
          </BubbleContainer>
        </BubbleAnimator>
        <Disc hideMobile={true} material="walled" zIndex={1} width={120} position="left: -140px; top: -60px;"/>
        <Wall id={"wall2"} hideMobile={true} height={64} position="top: 100px; left: -50px;"/>
        <Wall id={"wall3"} hideMobile={true} height={64} position="top: 200px; left: -50px;"/>
        <Wall id={"wall4"} hideMobile={true} height={90} position="bottom: 120px; right: -30px;"/>
        <Disc hideMobile={true} material="lightBlue" zIndex={6} width={120} position="right: -60px; top: 160px;"/>
      </WideImage>
      <NarrowContent>
        <GlassTextPanel zIndex={7} mb={80} material="transparent" className="motion" dataYFrom={40} dataYTo={-40}>
          <H3 strong={true} white={true}>
            {t.hero.bodyBelowImage}
          </H3>
        </GlassTextPanel>
        <Buttons className="center" mb={80}>
          <Button title={t.hero.bottomButtonTitle} linkTo={site.pages.contact.defaultPath}/>
        </Buttons>
        <H2 center={true} strong={true} mb={32}>
          <Wall id={"wall5"} hideMobile={true} height={120} longShadow={true} position="top: 0; right: -70px;"/>
          {t.walledDiscs.title}
        </H2>
      </NarrowContent>

      <SpaceBetween>
        <Wall id={"wall6"} hideMobile={true} height={90} position="bottom: 0; right: -70px;"/>
        <MaterialContainer minWidth={320} className="center" circle={true} columns={3} padding={60} material="walled">
          <P center={true}>{t.walledDiscs.disc1}</P>
        </MaterialContainer>
        <MaterialContainer minWidth={320} className="center" circle={true} columns={3} padding={60} material="walled">
          <P center={true}>{t.walledDiscs.disc2}</P>
        </MaterialContainer>
        <MaterialContainer minWidth={320} className="center" circle={true} columns={3} padding={60} material="walled">
          <P center={true}>{t.walledDiscs.disc3}</P>
        </MaterialContainer>
        <Disc hideMobile={true} material="lightBlue" zIndex={6} width={64} position="left: -60px; bottom: -40px;"/>
      </SpaceBetween>
      <Buttons className="center" mb={80}>
        <Button title={t.walledDiscs.buttonTitle} linkTo={site.pages.contact.defaultPath}/>
      </Buttons>


      <NarrowContent>
        <div style={{position: "relative"}}>
          <Wall id={"wall6"} hideMobile={true} height={90} position="top: -20px; left: -30px;"/>
          <Wall id={"wall7"} hideMobile={true} height={90} position="top: 100px; left: -30px;"/>
          <WalledBoxes zIndex={1} material="walled" mb={20}>
            <H2 strong={true} mb={32}>{t.walledBoxes.title}</H2>
            <MaterialContainer zIndex={1} padding={24} material="walled">
              <P>{t.walledBoxes.box1}</P>
            </MaterialContainer>
            <MaterialContainer mt={32} zIndex={1} padding={24} material="walled">
              <P>{t.walledBoxes.box2}</P>
            </MaterialContainer>
          </WalledBoxes>
        </div>
      </NarrowContent>
    </Layout>
  );
}

export default FinancierPage


const SpaceBetween = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 80px;
  
  @media only screen and (max-width: 767px) {
    margin-bottom: 20px;
    
    & > div {
      margin-bottom: 20px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    justify-content: space-between;
  }
`

const BubbleAnimator = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
`

const BubbleContainer = styled(MaterialContainer)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, -100%);
  
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: -140px;
    left: 24px;
    transform: unset;
    
    & > div {
      padding: 24px;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    position: absolute;
    bottom: 110px;
    top: unset;
    left: 30px;
    transform: unset;
    border-radius: 50%;
    width: 300px;
    
    & > div {
      padding: 48px;
    }
  }
`

const GlassTextPanel = styled(MaterialContainer)`
  margin-top: -80px;
  margin-bottom: 90px;
  
  @media only screen and (max-width: 767px) {
    margin-top: -40px;
    margin-bottom: 40px;
    
    & > h3 {
      font-family: Roboto, sans-serif;
      font-weight: 500;
      font-size: 18px;
      line-height: 140%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    margin-top: -140px;
  }
`

const TextUnderPageTitle = styled(H3)`
  @media only screen and (max-width: 767px) {
    margin-top: -16px;
  }
`

const WalledBoxes = styled(MaterialContainer)`
  @media only screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`
